import "./index.css";

import React, { FC, Suspense, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./components/root/App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./assets/themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import ScrollToTop from "./components/pages/common/ScrollToTop";

import * as ReactDOMClient from "react-dom/client";
import { Header } from "./components/root/layout/header/Header";
import { Section } from "./components/root/layout/section/Section";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import nprogress from "nprogress";
import { Replay } from "@mui/icons-material";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error }: { error: any }) {
  // const appInsights = useAppInsightsContext();
  // appInsights.trackException({ exception: new Error(error.message), severityLevel: SeverityLevel.Error });
  // const history = useHistory();
  return (
    <>
      <Header></Header>
      <Section>
        <Box display="flex" justifyContent="center" style={{ flexDirection: "column", maxWidth: "300px", marginLeft: "auto", marginRight: "auto" }}>
          <Typography variant="h5">
            <div role="alert">
              <p style={{ textAlign: "center" }}>Something went wrong:</p>
              <pre style={{ color: "red", textAlign: "center" }}>{error.message}</pre>
            </div>
          </Typography>
          <Button
            onClick={() => {
              // const u = new URL(window.location.href);
              // strip out the query string that might be causing the error
              // and then push the path if it's not the root path
              // if (u.pathname != null) history.push(u.pathname);
              // then, reload for good measure
              window.location.reload();
              // not entirely sure why I shouldn't be using below instead of above, will readup later
              // history.go(0);
            }}
            variant="outlined"
            color="primary"
            startIcon={<Replay />}
          >
            Try reloading
          </Button>
        </Box>
      </Section>
    </>
  );
}
export interface LazyLoaderProps {
  delay?: number;
}

// TODO: should go into their own files
const LazyLoader: FC<LazyLoaderProps> = ({ delay = 250, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nprogress.start();
      setShow(true);
    }, delay);
    return () => {
      nprogress.done();
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? (
    <>
      <Header></Header>
      <Section>
        <Box display="flex" justifyContent="center" mt="50px">
          <div>
            <CircularProgress />
            <CircularProgress color="secondary" />
          </div>
        </Box>
      </Section>
    </>
  ) : null;
};
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container as Element);
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LazyLoader delay={300} />}>
          <React.StrictMode>
            <ScrollToTop />
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </React.StrictMode>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </ThemeProvider>
);
// ReactDOM.render(
//   <ThemeProvider theme={theme}>
//     <BrowserRouter>
//       <React.StrictMode>
//         <ScrollToTop />
//         <App />
//       </React.StrictMode>
//     </BrowserRouter>
//   </ThemeProvider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
