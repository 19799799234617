import "./App.css";
import { Route, Routes } from "react-router-dom";
import { PageManager } from "./PageManager";
function App() {
  return (
    <Routes>
      <Route path="/*" element={<PageManager />} />
    </Routes>
  );
}

export default App;
