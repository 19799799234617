import CacheBuster from "../../cache-buster";
import { Main } from "./Main";

export function PageManager() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: { loading: boolean; isLatestVersion: boolean; refreshCacheAndReload: () => null }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return <Main />;
      }}
    </CacheBuster>
  );
}
