import { Section } from "../../../../components/root/layout/section/Section";
import { Logo } from "../ui/Logo";
import { keyframes, styled, useTheme } from "@mui/material/styles";
import { Box, Checkbox, useMediaQuery } from "@mui/material";
import { color } from "../../../../assets/themes/theme";
import { NavLink, useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState } from "react";

const Divider = styled("div")({
  borderLeft: `2px solid ${color.secondary.light}`,
  height: "2.0rem",
});

const PrimaryNavigationNav = styled("nav")({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
});

const PrimaryNavigationUI = styled("ul")({
  display: "flex",
  listStyleType: "none",
});

const Redbar = styled("div")(({ theme }) => ({
  height: "3.125rem",
  background: color.primary.main,
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
}));

const SecondaryNavButton = styled(NavLink)(({ theme }) => ({
  background: "transparent",
  border: "None",
  textDecoration: "none",
  cursor: "pointer",
  "&::after": {
    [theme.breakpoints.up("lg")]: {
      content: '""',
      display: "block",
      width: "100%",
      height: "2px",
      bottom: "0px",
      left: "0px",
      backgroundColor: color.grayTone.white,
      visibility: "hidden",
      transform: "scaleX(0)",
      "@media (prefers-reduced-motion: no-preference)": {
        transition: "all 0.4s ",
      },
    },
  },
  "&:hover::after": {
    transform: "scaleX(1)",
    visibility: "visible",
  },
}));

const SecondaryButtonText = styled("span")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: "1.125rem",
  color: color.primary.contrastText,
}));
const StyledIcon = styled(FavoriteIcon)(({ theme }) => ({
  position: "relative",
  top: "2px",
  cursor: "pointer",
  color: color.primary.main,
  fill: "white",
  fontSize: "1rem",
}));

const topBarFrames = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
  to {
    transform-origin: left top;
    transform: rotate(35deg) scale(1.2, 1);
  }
`;

const bottomBarFrames = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
  to {
    transform-origin: bottom left;
    transform: rotate(-35deg) scale(1.2, 1);
  }
`;

const topBarReverseFrames = keyframes`
  from {
    transform-origin: left top;
    transform: rotate(35deg) scale(1.2, 1);
  }
  to {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
`;

const bottomBarReverseFrames = keyframes`
	from {
    transform-origin: bottom left;
    transform: rotate(-35deg) scale(1.2, 1);
  }
  to {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
`;

export function Header() {
  const navigate = useNavigate();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const [isTopBarOpen, setIsTopBarOpen] = useState(false);

  const [displayOpacity, setDisplayOpacity] = useState<{ [key: number]: { display?: string; opacity?: number } }>({
    0: { display: "none", opacity: 0 },
    1: { display: "none", opacity: 0 },
    2: { display: "none", opacity: 0 },
    3: { display: "none", opacity: 0 },
    4: { display: "none", opacity: 0 },
  });

  const noPreferenceForReducedMotion = useMediaQuery("(prefers-reduced-motion: no-preference)");

  const handleTopBarOpenToggle = () => {
    setIsTopBarOpen(!isTopBarOpen);

    if (!isTopBarOpen) {
      setDisplayOpacity({
        0: { display: "block", opacity: 0 },
        1: { display: "block", opacity: 0 },
        2: { display: "block", opacity: 0 },
        3: { display: "block", opacity: 0 },
        4: { display: "block", opacity: 0 },
      });

      setTimeout(() => {
        setDisplayOpacity({
          0: { opacity: 1 },
          1: { opacity: 1 },
          2: { opacity: 1 },
          3: { opacity: 1 },
          4: { opacity: 1 },
        });
      }, 10);
    } else {
      setDisplayOpacity({
        0: { opacity: 0 },
        1: { opacity: 0 },
        2: { opacity: 0 },
        3: { opacity: 0 },
        4: { opacity: 0 },
      });

      setTimeout(
        () => {
          setDisplayOpacity({
            0: { display: "none" },
            1: { display: "none" },
            2: { display: "none" },
            3: { display: "none" },
            4: { display: "none" },
          });
        },
        noPreferenceForReducedMotion ? 250 : 0
      );
    }
  };

  return (
    <>
      <Section style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Logo />
        {smDown ? (
          <span style={{ marginLeft: "auto" }}>
            {/* <PrimaryAnchor
              aria-label="login"
              href="/login"
              onClick={() => {
                return true;
              }}
            >
              <PersonIcon fontSize="small" />
              <PrimaryIconText>Login</PrimaryIconText>
            </PrimaryAnchor> */}
          </span>
        ) : (
          <>
            <PrimaryNavigationNav aria-label="desktop navigation">
              <PrimaryNavigationUI>
                {/* <li>
                  <PrimaryAnchor href="https://store.ncqa.org" target="_blank" rel="noreferrer">
                    <ShoppingCart fontSize="small" aria-label="Shopping card" />
                    <PrimaryIconText>Shop our products</PrimaryIconText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor target="_blank" href="https://www.ncqa.org/about-ncqa/contact-us/" rel="noreferrer">
                    <EmailRounded fontSize="small" aria-label="contact us" />
                    <PrimaryIconText>Contact us</PrimaryIconText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    aria-label="login"
                    href="/login"
                    onClick={() => {
                      return true;
                    }}
                  >
                    <PersonIcon fontSize="small" />
                    <PrimaryIconText>Login</PrimaryIconText>
                  </PrimaryAnchor>
                </li> */}
              </PrimaryNavigationUI>
            </PrimaryNavigationNav>
          </>
        )}
      </Section>
      <nav>
        {lgDown ? (
          <Box style={{ background: color.primary.main, minHeight: "3.125rem", display: "flex" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "2px solid #80cce5",
                borderRadius: "5px",
                margin: "5px 10px",
                padding: "10px 10px",
                zIndex: "5",
                background: color.primary.main,
                gap: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <SecondaryNavButton
                  aria-label="special needs plan"
                  title="special needs plan"
                  to="/"
                  onClick={() => {
                    navigate("/");
                    handleTopBarOpenToggle();
                  }}
                >
                  <SecondaryButtonText>HOME</SecondaryButtonText>
                </SecondaryNavButton>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    id="checkbox"
                    inputProps={{
                      "aria-label": "toggle menu on mobile",
                    }}
                    checked={isTopBarOpen}
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      fontSize: 50,
                      opacity: 0,
                      zIndex: 1,
                      "&.Mui-checked": {
                        "& + div:first-of-type": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${topBarFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                          "@media (prefers-reduced-motion: reduce )": {
                            transformOrigin: "left top",
                            transform: "rotate(35deg) scale(1.2, 1)",
                          },
                        },
                        "& + div:first-of-type + div": {
                          opacity: 0,
                        },
                        "& + div:first-of-type + div + div": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${bottomBarFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                          "@media (prefers-reduced-motion: reduce)": {
                            transformOrigin: "bottom left",
                            transform: "rotate(-35deg) scale(1.2, 1)",
                          },
                        },
                      },
                      "&:not(.Mui-checked)": {
                        opacity: 0,
                        "& + div:first-of-type": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${topBarReverseFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                        },
                        "& + div:first-of-type + div": {
                          opacity: 1,
                        },
                        "& + div:nth-of-type + div + div": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${bottomBarReverseFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                        },
                      },
                    }}
                    onClick={() => {
                      handleTopBarOpenToggle();
                    }}
                  />
                  <Box
                    sx={{
                      borderBottom: "2px solid #80cce5",
                      width: "20px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: "2px solid #80cce5",
                      width: "20px",
                      "@media (prefers-reduced-motion: no-preference)": {
                        transition: "opacity 500ms",
                      },
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: "2px solid #80cce5",
                      width: "20px",
                    }}
                  ></Box>
                </Box>
              </Box>

              <Box
                sx={{
                  borderTop: "1px solid #80cce5",
                  padding: "10px 0 0 0",
                  display: displayOpacity[0].display,
                  opacity: displayOpacity[0].opacity,
                  "@media (prefers-reduced-motion: no-preference)": {
                    transition: "opacity 500ms",
                  },
                }}
              >
                <SecondaryNavButton
                  aria-label="list view"
                  title="list view"
                  to="snp/list"
                  onClick={() => {
                    navigate("snp/list");
                    handleTopBarOpenToggle();
                  }}
                >
                  <SecondaryButtonText>{`Model of Care Scores`}</SecondaryButtonText>
                </SecondaryNavButton>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #80cce5",
                  padding: "10px 0 0 0",
                  display: displayOpacity[1].display,
                  opacity: displayOpacity[1].opacity,
                  "@media (prefers-reduced-motion: no-preference)": {
                    transition: "opacity 500ms",
                  },
                }}
              >
                <SecondaryNavButton
                  aria-label="about the program"
                  title="about the program"
                  to="/about-the-program"
                  onClick={() => {
                    navigate("/about-the-program");
                    handleTopBarOpenToggle();
                  }}
                >
                  <SecondaryButtonText>About the Program</SecondaryButtonText>
                </SecondaryNavButton>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #80cce5",
                  padding: "10px 0 0 0",
                  display: displayOpacity[2].display,
                  opacity: displayOpacity[2].opacity,
                  "@media (prefers-reduced-motion: no-preference)": {
                    transition: "opacity 500ms",
                  },
                }}
              >
                <SecondaryNavButton
                  aria-label="what is model of care"
                  title="what is model of care?"
                  to="/what-is-model-of-care"
                  onClick={() => {
                    navigate("/what-is-model-of-care");
                    handleTopBarOpenToggle();
                  }}
                >
                  <SecondaryButtonText>What is a Model of Care?</SecondaryButtonText>
                </SecondaryNavButton>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #80cce5",
                  padding: "10px 0 0 0",
                  display: displayOpacity[3].display,
                  opacity: displayOpacity[3].opacity,
                  "@media (prefers-reduced-motion: no-preference)": {
                    transition: "opacity 500ms",
                  },
                }}
              >
                <SecondaryNavButton
                  aria-label="resources for snps"
                  title="resources for snps"
                  to="/resources-for-snps"
                  onClick={() => {
                    navigate("/resources-for-snps");
                    handleTopBarOpenToggle();
                  }}
                >
                  <SecondaryButtonText>Resources for SNPs</SecondaryButtonText>
                </SecondaryNavButton>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #80cce5",
                  padding: "10px 0 0 0",
                  display: displayOpacity[4].display,
                  opacity: displayOpacity[4].opacity,
                  "@media (prefers-reduced-motion: no-preference)": {
                    transition: "opacity 500ms",
                  },
                }}
              >
                {/* <SecondaryNavButton
                  aria-label="my saved"
                  title="my saved"
                  to="/mysaved"
                  onClick={() => {
                    navigate("/my-saved");
                    handleTopBarOpenToggle();
                  }}
                >
                  <StyledIcon></StyledIcon>
                  <SecondaryButtonText>My Saved</SecondaryButtonText>
                </SecondaryNavButton> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <Redbar style={{ display: "flex", lineHeight: 1 }}>
            <Section style={{ width: "100%" }}>
              <span style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                <SecondaryNavButton
                  aria-label="special needs plan"
                  title="special needs plan"
                  to="/"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <SecondaryButtonText>HOME</SecondaryButtonText>
                </SecondaryNavButton>
                <span style={{ display: "grid", alignItems: "center" }}>
                  <Divider />
                </span>
                <SecondaryNavButton
                  aria-label="list view"
                  title="list view"
                  to="snp/list"
                  onClick={() => {
                    navigate("snp/list");
                  }}
                >
                  <SecondaryButtonText>{`Model of Care Scores`}</SecondaryButtonText>
                </SecondaryNavButton>
              </span>
              <span style={{ marginLeft: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
                <SecondaryNavButton
                  aria-label="about the program"
                  title="about the program"
                  to="/about-the-program"
                  onClick={() => {
                    navigate("/about-the-program");
                  }}
                >
                  <SecondaryButtonText>About the Program</SecondaryButtonText>
                </SecondaryNavButton>
                <SecondaryNavButton
                  aria-label="what is model of care"
                  title="what is model of care?"
                  to="/what-is-model-of-care"
                  onClick={() => {
                    navigate("/what-is-model-of-care");
                  }}
                >
                  <SecondaryButtonText>What is a Model of Care?</SecondaryButtonText>
                </SecondaryNavButton>
                <SecondaryNavButton
                  aria-label="resources for snps"
                  title="resources for snps"
                  to="resources-for-snps"
                  onClick={() => {
                    navigate("/resources-for-snps");
                  }}
                >
                  <SecondaryButtonText>Resources for SNPs</SecondaryButtonText>
                </SecondaryNavButton>

                {/* <SecondaryNavButton
                  aria-label="my saved"
                  title="my saved"
                  to="my-saved"
                  onClick={() => {
                    navigate("/my-saved");
                  }}
                >
                  <StyledIcon></StyledIcon>
                  <SecondaryButtonText>My Saved</SecondaryButtonText>
                </SecondaryNavButton> */}
              </span>
            </Section>
          </Redbar>
        )}
      </nav>
    </>
  );
}
